<template>
  <div >
   
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Logout
        </v-card-title>
        <v-card-text>Are you sure you want to logout?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="logout"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-toolbar  flat>
      <!-- <v-toolbar-title>
        <span class="caption">Overview Dashboard></span><br>Tranding View
      </v-toolbar-title> -->
      <v-app-bar flat color="rgba(0,0,0,0)" class="ml-16">
        <h3>വിജ്ഞാന നഗരം - വായനശാല വാതിൽപ്പടിയിൽ</h3>
        <!-- <v-text-field
            label="Search"
            append-icon="mdi-magnify"
          ></v-text-field> -->
      </v-app-bar>
      <v-spacer></v-spacer>
      <!-- <v-btn icon class="pr-5">
        <v-badge
        :content="1"
        :value="1"
        color="green"
        overlap
      >
        <v-icon>far fa-bell</v-icon>
        </v-badge>
      </v-btn> -->
      
      <!-- <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
        <v-avatar size="40">
          <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
        </v-avatar>
      </v-badge> -->
    
      <v-btn color="red lighten-1" dark @click="dialog = true"> <v-icon>mdi-logout</v-icon>
              Logout
      </v-btn>
 
    </v-toolbar>
    <v-divider></v-divider>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data(){
    return{
      dialog:false,
    };
  },
methods:{
  logout(){
    this.dialog=false;
    store.state.loginAuth=false;
    // console.log("routyyy");
    console.log(store.state.loginAuth);
    this.$router.push("/");
  }
},
}
</script>

<style>

</style>