<template>
<div>
<v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5 pa-10">
          Please check your username or password
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-container class="container" >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="5">
          <v-card class="elevation-3 mt-10">
            <v-window >
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-text class="mt-12">
                      <v-row justify="space-around">
    <v-avatar color="blue">
      <v-icon dark>
        mdi-book-open-page-variant-outline</v-icon>
    </v-avatar>
                      </v-row>
                      <br>
                      <h4 class="text-center">Login in to Your Account</h4>
                      <h6 class="text-center grey--text">
                        Log in to your account so you can continue builiding
                        <br />and editing your onboarding flows
                      </h6>
                      
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <form @submit.prevent>
                            <v-text-field
                              label="Username"
                              name="user_name"
                              placeholder="Email"
                              v-model="userName"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-16"
                              prepend-inner-icon="mdi-account-outline"
                            />
                            <v-text-field
                              label="Password"
                              name="password"
                            placeholder="password"
                              v-model="password"
                              outlined
                              dense
                              color="blue"
                              type="password"
                              prepend-inner-icon="mdi-lock-outline"
                            />
                            <v-btn
                              type="Submit"
                              color="blue"
                              dark
                              block
                              tile
                              @click="login"
                              >Log in</v-btn
                            >
                            <br>
                            <br>
                          </form>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>  
</div>
</template>

<script>
import axios from 'axios';
export default {
data(){
    return{    
userName:"",
password:"",  
dialog:false,
    } ;

  },
  methods:{
      login(){
          axios.post("/admin/login",{
              "userName":this.userName,
              "password":this.password,
          }).then((response)=>{
              if(response.status===200){
                // store.state.loginAuth=true;
                // localStorage.auth=true;
                this.$router.push("/Home");
              }
          }).catch((error)=>{
              console.log(error);
              // alert('Something went wrong');
              this.dialog=true;
          });
      },
  },
}
</script>
<style lang="scss" >
.container{
 width: 100%;
 padding: 80px;
  

}

</style> 
