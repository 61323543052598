import axios from 'axios';
export default{
    namespaced:true,
    state(){
        return{
            checkOutDataList:[],
            checkOutList:[],
            bookDetails:[],
            patronDetails:[],
        };
    },
    getters:{
        getCheckOuts(state){
            // console.log("checkout data");
            // console.log(state.checkOutList);
            return state.checkOutList;
        },

        getBookDetails(state){
            return state.bookDetails;
        },
        getPatronDetails(state){
            return state.patronDetails;
        },
        getCheckOutDataList(state){
            return state.checkOutDataList;
        },
    },
    mutations:{
        setCheckOutList(state,data){
            state.checkOutList=[];
            data.forEach(element=>{
                state.checkOutList.push(element)
            })
            
        },

        setBookDetails(state,data){
            state.bookDetails.push(data);
        },

        setPatronDetails(state,data){
            state.patronDetails.push(data);
        },
        setCheckOutDataList(state,data){
            state.checkOutDataList.push(data);
        },
        setInitial(state){
            state.checkOutDataList=[];
            state.checkOutList=[];
            state.bookDetails=[];
            state.patronDetails=[];
        }
    },
    actions:{
        getCheckOutLists(context){
      axios.get("/checkout/get_all")
      .then((response)=>{
        if(response.status===200){
            // console.log("checkout data")
            // console.log(response.data)
            context.commit('setCheckOutList',response.data)
        }
      }).catch((error)=>{
        console.log(error);
      })
        }
        
    },


}