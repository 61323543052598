import Vue from 'vue'
import Vuex from 'vuex'
import user from '../store/modules/users';
import holds from '../store/modules/holds';
import checkouts from '../store/modules/checkout';
import deliverypartners from '../store/modules/deliverypartners';
import membershiplist from '../store/modules/memberships';
import checkinlist from '../store/modules/checkin';
import wardmemberlist from '../store/modules/wardmember';
import mobilemanagement from '../store/modules/mobilemanagement';
import createPersistedState from 'vuex-persistedstate';
import bookManagement from '../store/modules/managebooks';

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      loginAuth:false,
    };
  },
  getters: {
    getLoginAuth(state){
      return state.loginAuth;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Users:user,
    Holds:holds,
    CheckOuts:checkouts,
    DeliveryPartners:deliverypartners,
    MemberShipList:membershiplist,
    CheckinList:checkinlist,
    WardmemberList:wardmemberlist,
    MobileManagement:mobilemanagement,
    BookManagement:bookManagement,
  },
  plugins: [createPersistedState({
    key:"StateKey",
    paths:["loginAuth"]
  })],
})
