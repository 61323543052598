import axios from 'axios';
export default {
    namespaced:true,
    state(){
        return{
            wardmemberList:[],
        };
    },
    getters:{
        getWardmembers(state){
            return state.wardmemberList;
        }
    },
    mutations:{
        setWardMemberList(state,data){
            state.wardmemberList=[],
            data.forEach(element=>{
                state.wardmemberList.push({
                    name:element.name,
                    phoneNumber:element.phone_number,
                    wardNumber:element.ward_number
                });
            })
        },
        removeDeletedMember(state,phoneNumber){
            state.wardmemberList=state.wardmemberList.filter(member=>member.phoneNumber!==phoneNumber);
        },
        setAddMember(state,data){
            state.wardmemberList.push({
                    name:data.name,
                    phoneNumber:data.phone_number,
                    wardNumber:data.ward_number
            });
        }
    },
    actions:{
        getWardmemberList(context){
            axios.get("/admin/view-member-all")
            .then((response)=>{
                if(response.status===200){
                    return response.data;
                }
            }).then((data)=>{
                context.commit('setWardMemberList',data);
            }).catch((error)=>{
                console.log(error);
            })
        },
        deleteWardmember(context,phoneNumber){
            axios.post("/admin/delete-member",{
                "phone_number":phoneNumber,
            }).then((response)=>{
                if(response.status===200){
                    context.commit('removeDeletedMember',phoneNumber);
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        addWardmember(context,wardData){
            axios.post("/admin/add-member",{
                "phone_number":wardData.phoneNumber,
                "name":wardData.name,
                "ward_number":wardData.wardNumber,
                "password":wardData.password
            }).then((response)=>{
                if(response.status===200){
                    return response.data;
                }
            }).then((data)=>{
                context.commit('setAddMember',data)
            }).catch((error)=>{
                console.log(error);
            })
        }
    },
}