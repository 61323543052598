import axios from 'axios'
export default{
    namespaced:true,
    state(){
        return{
            bookList:[],
            searchedBookData:{}
        };
    },
    getters:{
        getBookList(state){
            return state.bookList;
        },
        getSearchedBook(state){
            return state.searchedBookData;
        }
    },
    mutations:{
        setBookList(state,data){
            state.bookList=[];
            data.forEach(element=>{
                state.bookList.push(element);
            })
        },
        removeBookList(state){
            state.bookList=[];
        },
        setSelectedBookData(state,data){
            state.searchedBookData=data;
        },
        addLoadedBookList(state,data){
            data.forEach(element=>{
                state.bookList.push(element);
            })
        }
    },
    actions:{
        async getBooks(context,bookName){
            const response=await axios.post("/koha/book/search-book-byname",{
                "txt":bookName.toUpperCase(),
            });
            // console.log(response.data);
            if(response.status===200){
                context.commit('setSelectedBookData',response.data);
                context.commit('setBookList',response.data.data);
            }
        },
        async loadMoreBooks(context,data){
            const response=await axios.post("/koha/book/search-book-byname",{
                "txt":data.txt.toUpperCase(),
                "page":data.pageNum
            });
            if(response.status===200){
                context.commit('setSelectedBookData',response.data);
                context.commit('addLoadedBookList',response.data.data);
            }
        },
        removeBook(context){
            context.commit('removeBookList');
        },
        addnewBook(context,data){
            axios.post("admin/add-book",{
                    "name":data.newBookName.toUpperCase(),
                    "biblioId":data.bibloId,
                    "items":data.itemsList,
                    "category":data.categorie,
            }).then((response)=>{
                if(response.status===200){
                    if(data.imageFile!=null){
                        let formData=new FormData();
                        formData.append("image",data.imageFile);
                        axios.post("/admin/add-book/image/"+data.bibloId,formData,{
                          headers:{
                            'Content-Type':'multipart/form-data'
                          },
                        }).then((res)=>{
                            console.log(res.status);
                            console.log(res.data);
                        })
                        .catch((error)=>{
                          console.log(error);
                        });
                       }
                }
            }).catch((error)=>{
                console.log(error);
            });
        }
    },
}