import axios from 'axios';
export default {
    namespaced:true,
    state(){
        return {
            membershipList:[],
        };
    },
    getters:{
        // to get data from membershiplist
        getMemberShip(state){
            return state.membershipList;
        }
    },
    mutations:{
        // to add data to membershiplist
        addMembersList(state,data){
            state.membershipList=[],
            data.forEach(element=>{
                state.membershipList.push(element);
            })
        },
        //to remove accepted membership from membershiplist
        removeMemberShip(state,phoneNumber){
            state.membershipList=state.membershipList.filter(memberShip=>memberShip.phoneNumber!==phoneNumber);
        },
        removeCancelMembership(state,phoneNumber){
            state.membershipList=state.membershipList.filter(memberShip=>memberShip.phoneNumber!==phoneNumber);
        }
    },
    actions:{
        // to get membershiplist
        getMemberShipList(context){
            axios.get("/user/get_membership_requests")
            .then((response)=>{
                // console.log("status code")
                // console.log(response.status)
                if(response.status===200){
                    return response.data;
                }
            }).then((data)=>{
                // console.log("data")
                // console.log(data)
                context.commit('addMembersList',data);
            })
            .catch((error)=>{
                console.log(error);
            })
        },
        // to pass phonenumber to mutation
        filterAcceptedMemberShip(context,phoneNumber){
            context.commit('removeMemberShip',phoneNumber);
        },
        //cancel membership
        cancelMembership(context,phoneNumber){
            axios.get("/user/reject_membership_request/"+phoneNumber).then((response)=>{
                if(response.status===200){
                    context.commit('removeCancelMembership',phoneNumber);
                }
            }).catch((error)=>{
                console.log(error);
            });
        },
        //approve user
        approveUser(context,userData){
            // console.log("approve action")
            // console.log(userData)
            axios.post("/user/accept_membership_request",{
                "phoneNumber":userData.phoneNumber,
                "cardNumber":userData.cardNumber,
                "category":userData.category,
                "dateEnrolled":userData.dateEnrolled,
                "expiryDate":userData.expiryDate
            }).then(response=>{
                if(response.status===200){
                    // console.log(response.data)
                    context.commit('removeMemberShip',response.data.phoneNumber)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    },
}