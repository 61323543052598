import axios from 'axios';
export default {
    namespaced:true,
    state(){
        return{
            holdList:[],
            bookNameList:[],
        };
    },
    getters:{
        getHoldList(state){
            // checkoutStatus
            // console.log("hold data");
            // console.log(state.holdList);
            state.holdList=state.holdList.filter(hold=>hold.checkoutStatus!=="T");
            return state.holdList;
        },
        getBookList(state){
            return state.bookNameList;
        }
    },
    mutations:{
        addHoldList(state,data){
            state.holdList=[];
            data.forEach(element=>{
                state.holdList.push(element);
            })
        },
        addBookNameList(state,data){
                state.bookNameList.push(data);
        },
        removeCheckoutData(state,holdId){
            // let index =state.holdList.findIndex(hold=>hold.holdId===holdId);
            state.holdList=state.holdList.filter(hold=>hold._id!==holdId);
            // state.bookNameList.splice(index,1);
        },
        removeCancelHold(state,holdId){
            // let index =state.holdList.findIndex(hold=>hold.holdId===holdId);
            state.holdList=state.holdList.filter(hold=>hold._id!==holdId);
            // state.bookNameList.splice(index,1);
        }
    },
    actions:{
         getHoldLists(context){
        axios.get("/hold/get_list")
        .then((response) => {
          if (response.status===200) {
            // console.log(response.data);
            // console.log(response.data)
            return response.data; 
          }
        }).then((data)=>{
            // console.log("hold data")
            // console.log(data)
            context.commit('addHoldList',data);
        }).catch((error)=>{
                    console.log(error.response);
                })
        },

        checkOuts(context,data){
            // console.log("checkout data")
            // console.log("data.accessionNo")
            // console.log(data.accessionNo)
            // console.log("data.barCode")
            // console.log(data.barCode)
            // console.log("data.bookName")
            // console.log(data.bookName)
            // console.log("data.holdId")
            // console.log(data.holdId)
            // console.log("data.userName")
            // console.log(data.userName)
            // console.log("data.cardNumber")
            // console.log(data.cardNumber)
            // console.log("data.phoneNumber")
            // console.log(data.phoneNumber)
            // console.log("data.houseName")
            // console.log(data.houseName)
            // console.log("data.wardNumber")
            // console.log(data.wardNumber)
            // console.log("data.wardName")
            // console.log(data.wardName)
            // console.log("data.postOffice")
            // console.log(data.postOffice)
            // console.log(data.pincode)
            axios.post("/checkout/place_checkout",{
                "accessionNo":data.accessionNo,
                "barcode":data.barCode,
                "bookName":data.bookName,
                "holdId":data.holdId,

                "userName":data.userName,
                "cardNumber":data.cardNumber,
                "phoneNumber":data.phoneNumber,
                "houseName":data.houseName,
                "wardNumber":data.wardNumber,
                "wardName":data.wardName,
                "postOffice":data.postOffice,
                "pinCode":"688013"
            }).then((response)=>{
                console.log("checkout doneee")
                this.$router.go();
                console.log(response.status)
                console.log(response.data)
                if(response.status===200){
                    context.commit('removeCheckoutData',data.holdId);
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        cancelHold(context,holdId){
            // console.log("hollddd IIIIIDddddddd");
            // console.log(holdId);
            axios.get("/hold/cancel_hold_admin/"+holdId).then((response)=>{
                // console.log("cancel hold response")
                // console.log(response.status);
                // console.log(response.data)
                if(response.status===200){
                    context.commit('removeCancelHold',holdId);
                } 
            }).catch((error)=>{
                console.log(error);
            });
            console.log("finish");
        }



       



    },
}