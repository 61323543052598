<template>

    <v-navigation-drawer  permanent color="#F4F5F9" app>
        <v-list-item class="px-2 py-5">
            <v-list-item-title class="text-capitalize" align="center">
                <!-- <v-icon x-large> fa-book</v-icon> {{library_address}} -->

<v-img src="https://thumbs.dreamstime.com/b/web-page-template-digital-library-banner-electronic-people-characters-vector-illustration-pass-online-courses-read-books-162679711.jpg"></v-img>

            </v-list-item-title>
        </v-list-item>
        <v-list nav dense>
            <template v-for="(item, i) in items">
                <v-list-item multiple color="deep-purple" :key="i" :to="item.route">
                    
                    
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    
                    <v-list-item-content>
                        
                        <v-list-item-title v-text="item.text"> </v-list-item-title>
                    
                    </v-list-item-content>
                    
                    
                    </v-list-item> 
            <!-- <v-divider inset :key="i"></v-divider> -->
            </template>
        </v-list>
        <!-- <template v-slot:append>
                <div class="pa-2">
                    <v-card align="center" class="pa-3">
                        <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10" class="mb-8">
                            <v-avatar size="40">
                                <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
                            </v-avatar>
                        </v-badge>
                        <h4 class="grey--text">Total Balance</h4>
                        <h2>$852.50</h2>
                        <h5 class="green--text">+$584.50 today</h5>
                        <v-card color="deep-purple" dark class="mt-5">
                            <v-list-item two-line>
                               <v-list-item-content>
                                   <v-list-item-title v-text="library_address">
                                      
                                   </v-list-item-title>
                                   <v-list-item-subtitle>
                                       3QjwF2YkhdygZ47Akjh
                                   </v-list-item-subtitle>
                               </v-list-item-content>
                            </v-list-item>             
                        </v-card>
                    </v-card>
                </div>
        </template> -->
    </v-navigation-drawer>
</template>
<script>
import axios from 'axios'
export default {
    data: () => ({
        selectedItem:0 ,
       library_address:"",
        items: [
            {icon: 'fas fa-home', text: 'Dashboard', route: '/dashboard'},
            {icon: 'fa-user-plus', text: 'Membership', route: '/user'},
            {icon: 'fas fa-dolly', text: 'Orders Placed', route: '/checkout'},
            {icon: 'fa-book', text: 'Book Requests', route: '/hold'},
            {icon: 'fa fa-retweet', text: 'Books Return',route:'/checkin'},
            {icon: 'fa fa-truck', text: 'Delivery Partner', route: '/deliverypartner'},
            // {icon: 'fa fa-user-plus', text: 'Membership Request', route: '/membership'},
            // {icon: 'fa-users', text: 'Ward Members', route: '/wardmember'},
            {icon: 'fa-solid fa-mobile', text: 'Mobile Management', route: '/mobilemanagement'},
            // {icon: 'fa-solid fa-book', text: 'Book Management', route: '/managebooks'},
        ]
        }),

//  mounted: function () {
//     this.post();
//   },
  methods: {
    post() {
      axios.post("/koha/library/get_library",{
         "id": "LLIB" 
      })
        .then((response) => {
          if (response.status===200) {
            return response.data; 
          }
        }).then((data) => {
            this.library_address=data.address1
    
         
        }).catch((error)=>{
            console.log(error);
        });

   
    },
  },
        
}
</script>
<style scoped>
div >>> .v-list a {
    text-decoration: none;
}
</style>