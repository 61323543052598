import Vue from 'vue'
import VueRouter from 'vue-router'
import LogInView from '../views/LogInView.vue'
import DefaultLayout from '../Layout/DefaultLayout.vue'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LogInView',
    component: LogInView,
  },

  
  {
    path: '/Home',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashBoardView.vue')
children:[
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
       component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashBoardView.vue'),
      //  beforeEnter:((to,from,next)=>{
      //   if(store.state.loginAuth===true){
      //     console.log(store.state.loginAuth);
      //     next()
      //   }else{
      //     next('/')
      //   }
      // }),
  },
  {
    path: '/user',
    name: 'user',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
 
  {
    path: '/deliverypartner',
    name: 'deliverypartner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "deliverypartner" */ '../views/DeliveryPartnerView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/hold',
    name: 'hold',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hold" */ '../views/HoldView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/checkout',
    name: 'checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "checkout" */ '../views/CheckOutView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/membership',
    name: 'membership',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "membership" */ '../views/MembershipView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/checkin',
    name: 'checkin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "checkin" */ '../views/CheckInView.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/wardmember',
    name: 'Wardmember',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/WardMember.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/mobilemanagement',
    name: 'MobileManagement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/MobileManagement.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/newreleases',
    name: 'NewReleases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/NewRelease.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
  {
    path: '/toptrends',
    name: 'TopTrends',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/TopTrends.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },

  {
    path: '/managebooks',
    name: 'ManageBooks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/ManageBooks.vue'),
    // beforeEnter:((to,from,next)=>{
    //   if(store.state.loginAuth===true){
    //     next()
    //   }else{
    //     next('/')
    //   }
    // }),
  },
]
  },
  {
    path: '*',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wardmember" */ '../views/NotFound.vue'),
  },
 
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
