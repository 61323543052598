<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  head:{
    link: [
      {
        rel: "icon",
        href: require("./assets/imgg.png")
      }
    ]
  },
  name: 'App',
};
</script>
