<template>
<div>
  <SideBar/>
      <NavBar />
      
          <router-view />
          </div>
    
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import NavBar from '@/components/NavBar.vue'


export default {
name:'DefaultLayout',
components:{
    SideBar,

    NavBar
}
}
</script>

<style>

</style>