import axios from 'axios';
export default {
    namespaced:true,
    state(){
        return{
            checkinList:[],
            // bookNameList:[],
        };
    },
    getters:{
        getCheckins(state){
            state.checkinList=state.checkinList.filter(checkIn=>checkIn.dpInHand==="T");
            return state.checkinList;
        },
        // getBookNameList(state){
        //     return state.bookNameList;
        // },
    },
    mutations:{
        setCheckinList(state,data){
            state.checkinList=[],
            data.forEach(element=>{
                state.checkinList.push(element);
            })
        },
        // setBookNameList(state,data){
        //     state.bookNameList.push(data.title);
        // },
        removeCheckedinData(state,checkout_id){
            // let index=state.checkinList.findIndex(checkin=>checkin._id===orderId);
            state.checkinList=state.checkinList.filter(checkin=>checkin._id!==checkout_id);
            // state.bookNameList.splice(index,1);
        }
    },
    actions:{
        getCheckinList(context){
            axios.get('/admin/get_all_return')
            .then((response)=>{
                if(response.status===200){
                    // console.log("check in data");
                    // console.log(response.data)
                    return response.data;
                }
            }).then((data)=>{
                context.commit('setCheckinList',data);
            }).catch((error)=>{
                console.log(error);
            })
        },
        checkIn(context,checkout_id){
            axios.get("/admin/conform_return_by_admin/"+checkout_id)
            .then((response)=>{
                if(response.status===200){
                    context.commit('removeCheckedinData',checkout_id);
                }
            }).catch((error)=>{
                console.log(error.response);
            })
        }
    },
}