import axios from 'axios';
export default{
  namespaced:true,
    state() {
        return{
          userList:[],
        };
      },
      getters: {
        getUsers(state){
          // console.log("users data");
          // console.log(state.userList);
          return state.userList;
        }
      },
      mutations: {
        addUserData(state,data){
            state.userList=[];
          data.forEach(element=>{
            state.userList.push(element);
          })
        },
        removeUser(state,cardNumber){
          state.userList=state.userList.filter(user=>user.cardNumber!==cardNumber);
        }
      },
      actions: {
        //get all users list
        getUserList(context){
          axios.get("/user/get_all_valid_users")
          .then((response)=>{
            if(response.status===200){
              return response.data;
            }
          }).then((data)=>{
            // console.log("users data")
            // console.log(data)
            context.commit('addUserData',data);
          }).catch((error)=>{
            console.log(error.response);
          })
        },
        deleteUser(context,cardNumber){
          // console.log("delete data")
          // console.log(cardNumber);
          context.commit('removeUser',cardNumber);
        },
      },
}