<template>
  <div id="background"  >
  <login-card/>
  </div>
</template>

<script>
import LoginCard from '../components/Login/LoginCard.vue';
export default {
  name: "login",
  components:{
    LoginCard,
  },
};
</script>

<style lang="scss" >
#background {
  background-image: url("../assets/library1.jpg");
  background-size: cover;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px; 
};
</style> 