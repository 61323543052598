import axios from 'axios';
export default {
    namespaced:true,
    state(){
        return{
            deliveryPartnerList:[],
            deliveryPartnerReq:[]
        }
    },
    getters:{
        getDeliveryPartners(state){
            return state.deliveryPartnerList;
        },
        getDeliveryPartnerReq(state){
            return state.deliveryPartnerReq;
        },
    },
    mutations:{
        addDeliveryPartner(state,data){
            state.deliveryPartnerList=[],
            data.forEach(element=>{
                // console.log("deliveryboy data");
                // console.log(element.ward_number);
                state.deliveryPartnerList.push(element);
            })
        },
        deliveryPartnerReq(state,data){
            state.deliveryPartnerReq=[]
            data.forEach(element=>{
                state.deliveryPartnerReq.push(element);
            })
        },
        deleteDeliveryPartner(state,phoneNumber){
            state.deliveryPartnerList=state.deliveryPartnerList.filter(partner=>partner.phoneNumber!==phoneNumber);
        },
        saveDeliveryPartner(state,data){
            state.deliveryPartnerList.push(data);
        },
        removeAccepteddeliveryPartner(state,phno){
            // console.log("phone number");
            // console.log(phno);
            state.deliveryPartnerReq=state.deliveryPartnerReq.filter(partner=>partner.phoneNumber!==phno)
        }
    },
    actions:{
        getDeliveryPartnerList(context){
            axios.get("/dp/get_all_valid_delivery_persons")
            .then((response)=>{
                if(response.status===200){
                    return response.data;
                }
            }).then((data)=>{
                // console.log("deliverpartner list data")
                // console.log(data)
                context.commit('addDeliveryPartner',data)
            }).catch((error)=>{
                console.log(error);
            })
        },
        getAllDeliveryPartnerReq(context){
            axios.get("/dp/get_delivery_person_applications")
            .then((response)=>{
                if(response.status===200){
                    return response.data;
                }
            }).then((data)=>{
                // console.log("delivery partner req data")
                // console.log(data)
                context.commit('deliveryPartnerReq',data)
            }).catch((error)=>{
                console.log(error);
            })
        },
        acceptDeliveryPartners(context,data){
            axios.post("/dp/approve_delivery_person",{
                "phoneNumber":data.phoneNumber,
                "wardNumber":data.wardNumber
            }).then((response)=>{
                // console.log(response.status)
                // console.log(response.data)
                // console.log(data.phoneNumber)
                if(response.status==200){
                    context.commit('removeAccepteddeliveryPartner',data.phoneNumber)
                }
            }).catch((error)=>{
                console.log(error.response)
            })
        },
        removeDeliveryPartner(context,phoneNumber){
                    context.commit('deleteDeliveryPartner',phoneNumber)
        }
    },
}