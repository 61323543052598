import axios from 'axios';
export default{
    namespaced:true,
    state(){
        return{
            bookList:[],
            newReleasesList:[],
            topTrendReleases:[],
            searchedBookData:{}
        };
    },
    getters:{
        getNewReleases(state){
            return state.newReleasesList;
        },
        getBookList(state){
            return state.bookList;
        },
        getTopTrendList(state){
            return state.topTrendReleases;
        },
        getSearchedBook(state){
            return state.searchedBookData;
        },
       
    },
    mutations:{
        setBookList(state,data){
            state.bookList=[];
            data.forEach(element=>{
                state.bookList.push(element);
            })
        },
        clearBookData(state){
            state.bookList=[];
        },
        setTopTrends(state,data){
            state.topTrendReleases=[];
            data.forEach(element=>{
               state.topTrendReleases.push(element);
            })
        },
        setTopReleases(state,data){
            state.newReleasesList=[];
            data.forEach(element=>{
                state.newReleasesList.push(element);
            })
        },
        deleteTopTrend(state,accessionNo){
            state.topTrendReleases=state.topTrendReleases.filter(trend=>trend.accessionNo!==accessionNo);
        },
        deleteNewReleases(state,accessionNo){
            state.newReleasesList=state.newReleasesList.filter(trend=>trend.accessionNo!==accessionNo);
        },
        setSelectedBookData(state,data){
            state.searchedBookData=data;
        },
        addLoadedBookList(state,data){
            data.forEach(element=>{
                state.bookList.push(element);
            })
        }
    },
    actions:{
       async getBooks(context,bookName){
            const response=await axios.post("/books/search",{
                "text":bookName.toUpperCase(),
            });
            if(response.status===200){
                context.commit('setSelectedBookData',response.data);
                context.commit('setBookList',response.data.data);
                
            }
            
        },
        async loadMoreBooks(context,data){
            const response=await axios.post("/books/search",{
                "text":data.txt.toUpperCase(),
                "page":data.pageNum
            });
            if(response.status===200){
                context.commit('setSelectedBookData',response.data);
                context.commit('addLoadedBookList',response.data.data);
            }
        },
        clearBooks(context){
            context.commit('clearBookData');
        },
        getTopTrends(context){
            axios.get("/books/get_trends").then((response)=>{
                // console.log(response.data)
                if(response.status===200){
                    context.commit('setTopTrends',response.data);
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        getTopReleases(context){
            axios.get("/books/get_release").then((response)=>{
                if(response.status===200){
                context.commit('setTopReleases',response.data);
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        addTopTrends(context,accessionNumber){
            axios.post("/books/add_to_trends/"+accessionNumber)
            .then((response)=>{
                console.log(response.status);
                // console.log(response.data);
            }).catch((error)=>{
                console.log(error);
            })
        },
        addNewRelease(context,accessionNumber){
            axios.post("/books/add_to_release/"+accessionNumber)
            .then((response)=>{
                console.log(response.status);
                // console.log(response.data);
            }).catch((error)=>{
                console.log(error);
            })
        },
        removeTopTrend(context,accessionNumber){
            axios.post("/books/remove_from_trends/"+accessionNumber)
            .then((response)=>{
                if(response.status===200){
                context.commit('deleteTopTrend',accessionNumber);
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        removeNewReleases(context,accessionNumber){
            axios.post("/books/remove_from_release/"+accessionNumber)
            .then((response)=>{
                if(response.status===200){
                context.commit('deleteNewReleases',accessionNumber);
                }
            }).catch((error)=>{
                console.log(error);
            })
        }
    }
}